import styled from 'styled-components'

export const Cards = styled.div`
max-width: 1400px;
margin: 0 auto;
display: flex;
position: relative;
justify-content: space-around;
margin-bottom: 10px;
flex-wrap: wrap;

@media(min-width: 600px) {
  
}
@media(min-width: 900px) {
  
}
`