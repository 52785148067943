import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import styled from "styled-components"
import Poster from "../images/Poster.png"
import { fontSize, textAlign } from "styled-system"
import Fade from "react-reveal/Fade"
import {
  AngelaFan,
  AilieFan,
  EricFan,
  JodieDriver,
  DianneSenior,
  ZoePilling,
  YueruiSun,
  GabrielaSeczkowska,
  SarahSingleton,
  PaulaJureczeko,
  AnnieWang,
  aboutBanner,
  AnnAustin,
  BridgetLane,
  BryanChadderton,
  ChrisHolsman,
  ClareBakerSmith,
  ChristineKwong,
  DenisSavage,
  HarrietJohnsonTsui,
  KatieSchad,
  LouiseHarrison,
  MichaelGrive,
  NataljaFakirova,
  NikMacKenzie,
  PhilDavis,
  TamsinBailey,
  YingZhang,
  BlankPortrait,
  TianGu,
  ZaraKenny,
} from "../images"
import { TeamContainer, TeamSubheading } from "../containers/Team"
import { Cards, Card } from "../components/TeamCards"
import ReactHtmlParser from "react-html-parser"
import { Awards, Accreditations } from "../containers/Awards&Accreditations"
import { StoryTimeline } from "../components/Timeline"
import { AboutTestimony } from "../components/Testimonials"
import TestimonialAccordion from "../components/Accordions/TestimonialAccordion"
import SEO from "../components/seo"

export const Block = styled.div`
  height: 400px;
  background-color: black;
`

export const FadeDivContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px;
`

const FadeParagraphContainer = styled.p`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: normal;
  text-align: center;
`

const FadeParagraph = ({ children }) => {
  return (
    <FadeDivContainer>
      <Fade>
        <FadeParagraphContainer>{children}</FadeParagraphContainer>
      </Fade>
    </FadeDivContainer>
  )
}

const FadeImage = props => {
  const { image, description } = props
  return (
    <Fade>
      <img
        src={image}
        style={{
          borderRadius: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      />
      <div
        style={{
          fontFamily: "sans-serif",
          marginTop: "20px",
          marginBottom: "50px",
          textAlign: "left",
        }}
      >
        {description}
      </div>
    </Fade>
  )
}

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 100px;
  }
  @media (max-width: 740px) {
    margin: 11px;
  }
`

const sidebar = [
  {
    to: "aboutUs",
    title: "About Us",
  },
  {
    to: "ourTeam",
    title: "Our Team",
  },
  {
    to: "ourStory",
    title: "Our Story",
  },
  {
    to: "ourAchievements",
    title: "Awards and Recognitions",
  },
  {
    to: "Testimonials",
    title: "Testimonials",
  },
  {
    to: "ourAccreditations",
    title: "Accreditations",
  },
]

export default function About({ data }) {
  console.log(data)
  const html = data.wpPage.content
  const processedData = ReactHtmlParser(html)
  return (
    <Layout
      sidebar={sidebar}
      pageTitle="About Us"
      background={aboutBanner}
      pageDescription="We are experienced and dedicated to helping students achieve their dreams.  Here is our story…"
    >
      <SEO
        title="About | Guardianship | UK Education | Student Education"
        description="Study Links was founded in 1998 and recognised one of the UK’s leading and most established guardianship companies. Find out more about us. "
      />
      <Section id="aboutUs">
        <br />
        <FadeParagraph>
          Founded in 1998 and recognised as an award-winning family-run
          business, we are proud to say that we are one of the UK’s leading and
          most established guardianship companies.
        </FadeParagraph>
        <FadeParagraph>
          The Study Links team’s personal experiences of studying and teaching
          in the UK and other countries help inform our approach to supporting
          students.
        </FadeParagraph>
      </Section>
      <Section id="ourTeam">
        <FadeH1>Our Team</FadeH1>
        <TeamContainer>
          <TeamSubheading>Leadership Team</TeamSubheading>
          <Cards>
            <Card title="Angela Fan" text="Founder" img={AngelaFan} />
            <Card title="Eric Fan" text="Director" img={EricFan} />
            <Card title="Ailie Fan" text="Managing Director" img={AilieFan} />
          </Cards>
          {/* <Cards>
            <Card
              title="Dianne Senior"
              text="Operations Lead"
              img={DianneSenior}
            />
            <Card
              title="Zoe Pilling"
              text="Guardianship Lead"
              img={ZoePilling}
            />
          </Cards> */}
          <TeamSubheading>Finance Team</TeamSubheading>
          <Cards>
            <Card
              title="Sarah Singleton"
              text="Bookkeeper"
              img={SarahSingleton}
            />
          </Cards>
          <TeamSubheading>Marketing Team</TeamSubheading>
          <Cards>
            <Card
              title="Lisa Howard"
              text="Marketing Executive"
              img={BlankPortrait}
            />
          </Cards>
          <TeamSubheading>Student Services Team</TeamSubheading>
          <Cards>
            {/* <Card
              title="Annie Wang"
              text="Student Mentor (maternity leave)"
              img={AnnieWang}
            /> */}
            <Card
              title="Jodie Driver"
              text="Student Service Lead"
              img={JodieDriver}
            />
            <Card
              title="Gabriela Seczkowska"
              text="Student Service Coordinator"
              img={GabrielaSeczkowska}
            />
            <Card
              title="Tian Gu"
              text="Student Service Coordinator"
              img={TianGu}
            />
            {/* <Card
              title="Christine Kwong"
              text="Operations Coordinator"
              img={ChristineKwong}
            /> */}
            {/* <Card
              title="Fiona Boalch"
              text="Office administrator"
              img={BlankPortrait}
            /> */}
          </Cards>
          <Cards>
            <Card
              title="Paula Jureczko"
              text="Student Service Coordinator"
              img={PaulaJureczeko}
            />
            <Card
              title="Zara Kenny"
              text="Student Service Coordinator"
              img={ZaraKenny}
            />
            {/* <Card title="Yuerui Sun" text="Student Mentor" img={YueruiSun} /> */}
          </Cards>
          <TeamSubheading>Academic Consultants</TeamSubheading>
          <Cards>
            <Card
              title="Ann Austin"
              text="Academic Consultant"
              img={AnnAustin}
            />
            <Card
              title="Bridget Lane"
              text="Academic Consultant"
              img={BridgetLane}
            />
            <Card
              title="Bryan Chadderton"
              text="Academic Consultant"
              img={BryanChadderton}
            />
          </Cards>
          <Cards>
            <Card
              title="Chris Holsman"
              text="Academic Consultant"
              img={ChrisHolsman}
            />
            <Card
              title="Clare Baker-Smith"
              text="Academic Consultant"
              img={ClareBakerSmith}
            />
            <Card
              title="Denis Savage"
              text="Academic Consultant"
              img={DenisSavage}
            />
          </Cards>
          <Cards>
            <Card
              title="Harriet Johnson-Tsui"
              text="Academic Consultant"
              img={HarrietJohnsonTsui}
            />
            <Card
              title="Katie Schad"
              text="Academic Consultant"
              img={KatieSchad}
            />
            <Card
              title="Louise Harrison"
              text="Academic Consultant"
              img={LouiseHarrison}
            />
          </Cards>
          <Cards>
            <Card
              title="Michael Grive"
              text="Academic Consultant"
              img={MichaelGrive}
            />
            <Card
              title="Natalja Fakirova"
              text="Academic Consultant"
              img={NataljaFakirova}
            />
            <Card
              title="Nik MacKenzie"
              text="Agency Liaison Officer"
              img={NikMacKenzie}
            />
          </Cards>
          <Cards>
            <Card
              title="Phil Davis"
              text="Academic Consultant"
              img={PhilDavis}
            />
            <Card
              title="Stuart Mills"
              text="Academic Consultant"
              img={BlankPortrait}
            />
            <Card
              title="Tamsin Bailey"
              text="Academic Consultant"
              img={TamsinBailey}
            />
          </Cards>
          <Cards>
            <Card
              title="Ying Zhang"
              text="Academic Consultant"
              img={YingZhang}
            />
          </Cards>
        </TeamContainer>
      </Section>
      <Section id="ourStory">
        <FadeH1>Our Story</FadeH1>
        {/* {processedData.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data.props.children}</div>
              </Fade>
            )
          })} */}
        <br />
        <Fade>
          Study Links International was established in 1998 by husband and wife
          team, Eric and Angela Fan. Owing to their hard work, dedication and
          care, Study Links grew, evolving from a family business into the
          business we are today – a devoted company with a strong family ethos
          still at its core.
        </Fade>
        <br />
        <StoryTimeline />
        <br />
        <Fade>
          Our company has gone from strength to strength, with growing prestige
          and reputation for being knowledgeable and experienced in our field.
          We still remain true to the family values at the heart of the company,
          every student in our care benefits from these. Study Links understands
          that their experience in the UK is important for their future, we help
          make this chapter of their life a success, and instil them with the
          confidence, skills and tools to write the next.
        </Fade>
      </Section>
      <Section id="ourAchievements">
        <FadeH1>Awards and recognitions</FadeH1>
        <Awards />
      </Section>
      <Section id="Testimonials">
        <FadeH1>Testimonials</FadeH1>
        <TestimonialAccordion />
      </Section>
      <Section id="ourAccreditations">
        <FadeH1>Our Accreditations</FadeH1>
        <Accreditations />
      </Section>
      <AboutTestimony />
    </Layout>
  )
}

export const STUDYLINKSHISTORY = graphql`
  query StudyLinksHistory {
    wpPage(title: { eq: "api-studylinks-history" }) {
      title
      content
    }
  }
`
