import React from 'react'
import {
  Timeline,
  Events,
  ImageEvent,
  TextEvent,
} from '@merc/react-timeline';
import { story1, story2, story3, story4, story5, story6, story7, story8, story9 } from "../../images";

export function StoryTimeline() {
  return (
    <Timeline>
      <Events>
        <ImageEvent
          date="1976"
          text="The true history of Study Links goes back to 1976 when Eric Fan, a young student from Hong Kong, moved to England to begin his own journey through the UK education system.
          "
          src={story1}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <ImageEvent
          date="1976"
          text="Eric began studying at a Sixth Form College  where he met Angela. They were studying for  A-Levels and then graduated from university.
          "
          src={story2}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <ImageEvent
          date="1983"
          text="In 1983, the young couple moved to Hong Kong where Eric taught Mathematics and Computer Science and Angela taught English in schools Angela became fluent in Cantonese and the couple got married and had two children.
          "
          src={story3}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <ImageEvent
          date="1988"
          text="After 5 years in Hong Kong, the couple decided to move back to the UK so that their children could benefit from a British education. Eric was offered a job at a large company in Bradford, and they had a third child, Ailie.
          "
          src={story6}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <ImageEvent
          date="1989"
          text="Eric’s nephew, Andy wanted to study in the UK, so they found him a good boarding school and he came to stay with them in the holidays. This was their first experience of being a Guardian.
          "
          src={story7}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <ImageEvent
          date="1990-98"
          text="This worked so successfully that the following year his younger sister Carmen and two children of family friends came to study at schools chosen by the couple who acted as their Guardians.
          Angela continued teaching English to international students at a local FE College, and travelled the world on behalf of the college to recruit students to study in the UK.
          During the 90s an ever-growing number of friends, family members, acquaintances and friends of friends came to the UK, with Angela and Eric continuing to help find suitable schools and act as their guardians. It was a lot of rewarding but hard work, to ensure all the students were happy and safe. It was great to see how, with their help, the students progressed and thrived and how appreciated this was by their parents.
          "
          src={story8}
          alt="jellyfish swimming"
        >
        </ImageEvent>
        <TextEvent
          date="1998"
          text="As they received more and more requests for Guardianship, they decided in 1998 to set up their own Guardianship company, which started life at their dining room table.
          As they weren’t able to accommodate all the students themselves, they asked friends and family members to become host families to provide caring, welcoming homes for these students, who attended boarding schools mainly in the Yorkshire area."
        />
        <TextEvent
          date="2008"
          text="The company rapidly grew in size and reputation with families and schools all over the UK and by 2008, Study Links had its own business premises, employed five full time staff and had many more homestays on board."
        />
        <TextEvent
          date="2013"
          text="We hosted an ‘Education in the UK’ event at the British Embassy in Beijing in 2013 inviting special guest speakers from within the industry. Its purpose was to promote the UK Education system and was attended by elite schools, businesses, parents and students from throughout China. The event was very well received, resulting in positive press and television coverage."
        />
        <ImageEvent
          date="Now"
          text="The company continues to grow and thrive, with Guardianship students from many nationalities at schools throughout the UK as well as a network of part-time members of staff and homestays. Their daughter Ailie, having studied Chinese and Business, now plays an integral role within the business.
          "
          src={story9}
          alt="jellyfish swimming"
        >
        </ImageEvent>
      </Events>
    </Timeline>
  );
}

