import styled from "styled-components"
import { Link } from "gatsby";

export const ItemImage = styled.img`
  box-shadow: ${({bshadow}) => (bshadow ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : 'none')};
  max-width: calc(120px + 10vw);
  height: auto;
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 950px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const ItemText = styled.p`

`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: center;
  max-width: 600px;
`

export const ItemImageContainer = styled.div`
  max-width:300px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: center;
`

export const ItemImageDescription = styled.h3`
  font-size: 15px;
  text-align: center;
  margin: 5px;
`

export const ItemTitle = styled.h2 `
  font-size: 30px;
`

export const BlogLink = styled(Link)`

`