import React from "react"
import styled from "styled-components"

const Indented = styled.div`
  margin-left: 20px;
`

const TestimonialAccordion = () => {
  return (
    <div id="accordion" class="accordion">
      <div class="accordion-item">
        <div class="accordion-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Dominic Wang – Student at Leeds English Language School, Notre
              Dame Catholic Sixth Form College, and University of Oxford.
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            "Study Links helped me during the darkest days in my life, when I
            was a helpless child who had just landed in a foreign country
            knowing little of the language and fearful of what would happen. It
            was Angela and her family who acted as guides and mentors in my
            first few months, warmly introducing me to the language and the
            culture. They filled my early days with laughter and joy, where it
            may well have been full of loneliness and sorrow, so that I could
            have the confidence to pursue what my heart desired. My homestay was
            so kind that I was very sad to leave the family after a whole year
            of staying with them. My guardian, Angela, would take me out every
            few months to check that I was happy with my life. “The school was
            as good as I could hope for. My classmates were people from ethnic
            groups from all around the world, and the teachers were all there to
            help you. It is with the help of these lovely people, that I made
            such an improvement in the language over such a short space of time
            and became confident enough to compete with my British classmates.
            “I can't thank you enough, Study Links. If there were no Study
            Links, there would be no me as I am today. You have all my good
            wishes, and I am so happy to see more and more students receive the
            happiness and guidance as I once had."
            <a
              href="https://www.youtube.com/watch?v=BfmWCi1OqU4"
              target="_blank"
            >
              {" "}
              Video
            </a>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Vivian Chung, Parent of Grace Chung
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            “My daughter has now completed the first year of her guardianship
            with Study Links. I would like to express my deepest gratitude to
            you all for introducing both the school and a great homestay to me
            and my daughter Grace. Irrespective of my daughter’s difficulties in
            adjusting to the UK and the school, this year my daughter is doing
            unexpectedly well in managing her study and life in school. This is
            attributed to:
            <br />
            <br />
            <ul>
              <li>
                The professional team of guardians at Study Links who have
                provided well organised care with knowledge and empathy.
              </li>
              <li>
                The good academic and administrative foundations of Ackworth
                School. The constant care and professionalism of the staff and
                teachers there.
              </li>
            </ul>
            A short message like this can only reveal a small part of my
            gratitude.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingThree">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Mr Wong, Parent of Samson and Samuel Wong
            </button>
          </h2>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div className="accordion-body">
            “Study Links cared for my two sons during their time at school in
            England. Whenever my boys had any problems the Study Links team were
            there to help, offering valuable, caring advice and practical
            solutions. We have continued to keep in touch with Angela and her
            family since my sons left England and we are still great friends.”
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingFour">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Mrs Chen, Parent of Linda and Sam Chen
            </button>
          </h2>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            “During our children's time in the UK, they were both thoroughly
            supported by Angela and Study Links. Study Links kept in regular
            contact with both our children and us so we had complete peace of
            mind at all times. Angela and Study Links cared for my children like
            they were their own children and for this I am grateful. I never
            worried for their safety when they were under Study Links' care. I
            would always recommend their service to any parents thinking of
            sending their children to the UK.”
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialAccordion
