import styled from 'styled-components'
import Fade from "react-reveal/Fade";
import React from 'react'

export const TeamContainer = styled.div`
  position: relative;
  margin: 20px;
  height: auto;
  box-sizing: border-box;
  /* min-height: 1000px; */
`

export const TeamSubheadingWithoutFade = styled.div `
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
`

export const TeamSubheading = ({children}) => {
  return (
    <Fade>
      <TeamSubheadingWithoutFade>
        {children}
      </TeamSubheadingWithoutFade>
    </Fade>
  )
}