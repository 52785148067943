import React from "react"
import {
  ItemContainer,
  ItemText,
  ItemImageContainer,
  ItemImage,
  ItemImageDescription,
  ItemTitle,
  TextContainer,
  BlogLink,
  MainContainer,
} from "./elements"
import {
  award1,
  award2,
  award3,
  award4,
  award5,
  award6,
  award7,
  award8,
  award9,
  family_business,
  logo,
  accreditations1,
  accreditations2,
  accreditations3,
  accreditations4,
  accreditations5,
  accreditations6,
  accreditations7,
  accreditations8,
  CEOToday,
  AIExcellence,
  SmallBusiness,
  InfluentialBusinessWoman,
} from "../../images"
import Fade from "react-reveal/Fade"

const Item = props => {
  const { imageSource, title, text, description, link, bshadow } = props
  return (
    <Fade>
      <ItemContainer>
        <TextContainer>
          <ItemTitle>{title}</ItemTitle>
          <ItemText>{text}</ItemText>
          {/* <BlogLink to={link}>Click here to find out more</BlogLink> */}
        </TextContainer>
        <ItemImageContainer>
          <ItemImage src={imageSource} bshadow={bshadow} />
          <ItemImageDescription>{description}</ItemImageDescription>
        </ItemImageContainer>
      </ItemContainer>
    </Fade>
  )
}

export const Accreditations = () => {
  return (
    <MainContainer>
      <Item
        text="Study Links is a member of Safe Schools UK initiative – Safe Schools UK initiative was created after the UK government announced to the nation the roadmap to ending lockdown. Safe Schools UK initiative has been set up to allow schools to quickly and clearly communicate to students, parents and their communities the safety measures that will be in place."
        imageSource={accreditations1}
        description=""
        title="Safe Schools initiative"
        link="/"
      />
      <Item
        text="Study Links is fully accredited by AEGIS – The Association for the Education & Guardianship of International Students. All of our processes are regularly inspected and audited by AEGIS to ensure that we maintain the highest level of service for the students in our care."
        imageSource={accreditations2}
        description=""
        title="Association for the Education & Guardianship of International Students"
        link="/"
      />
      <Item
        text="Study Links is accredited as a Living Wage employer. The Living Wage is an hourly rate set independently and updated annually. The Living Wage is calculated according to the basic cost of living in the UK. Employers choose to pay the Living Wage on a voluntary basis. The Living Wage enjoys cross party support, with public backing from the Prime Minister and the Leader of the Opposition. The Living Wage Foundation recognises and celebrates the leadership shown by Living Wage employers across the UK. There are currently over 1,200 accredited employers."
        imageSource={accreditations3}
        description=""
        title="Living Wage Employer"
        link="/"
      />

      <Item
        text="Study Links is a member of the Bradford Chamber of Commerce. Bradford Chamber provides support services to all businesses across the district. They help businesses open up new markets, and ensure that their views are heard by those within local and national government."
        imageSource={accreditations5}
        description=""
        title="Bradford Chamber of Commerce"
        link="/"
      />
    </MainContainer>
  )
}

export const Awards = () => {
  return (
    <MainContainer>
      <Item
        text="We are pleased to announce we are winners in the Small Business Awards 2021. We have won an award for 'Best International Student Support Provider- West Yorkshire"
        imageSource={SmallBusiness}
        description=""
        title="Small Business Awards 2021"
        link="/"
        bshadow="true"
      />
      <Item
        text="Studylinks' International Ltd.
        Best International Student Support Director 2021: Ailie Fan"
        imageSource={InfluentialBusinessWoman}
        description=""
        title="Best International Student Support Director 2021"
        link="/"
        bshadow="true"
      />
      <Item
        text="Study Links won the CEO Today Magazine Global Award 2021 which recognises, identifies, and honours the most respected companies and their C-level executives operating across the globe today."
        imageSource={CEOToday}
        description=""
        title="CEO Today Magazine Global Award 2021"
        link="/"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={family_business}
        description=""
        title="Family business of the year 2019 (Shortlisted)"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={logo}
        description=""
        title="Export team of the year 2019 (Shortlisted)"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={AIExcellence}
        description=""
        title="Best Immersive Language Tuition Company – UK"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={AIExcellence}
        description=""
        title="AI Excellence Award for Immersive English Tuition 2017"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={award1}
        description=""
        title="AI Influential businesswoman awards 2017"
        bshadow="true"
      />
      <Item
        text="Study Links were shortlisted for Employer of the Year in the 2016 Telegraph and Argus Bradford Means Business Awards. Unfortunately we missed out to JCT600 but we were highly commended."
        imageSource={award2}
        description=""
        title="2016 Telegraph and Argus Bradford Means Business Awards (highly commended)"
        bshadow="true"
      />
      <Item
        text=""
        imageSource={award3}
        description=""
        title="Yorkshire people's choice award 2016"
        bshadow="true"
      />
      <Item
        text="Our founder Angela Fan was the winner of the Woman in Education Award at the 2016 Network She Foundation International Women's day awards in Llandudno (Wales)"
        imageSource={award6}
        description=""
        title="Woman in Education Award 2016"
        bshadow="true"
      />
      <Item
        text="Study Links was shortlisted for the Yorkshire & Humber China Business Awards 2014 in the ‘Established Business with China’ category. The Yorkshire & Humber China Business Awards are run by the Business Confucius Institute at the University of Leeds, supported by UK Trade and Investment and China-Britain Business Council."
        imageSource={award9}
        description=""
        title="Yorkshire & Humber China Business Awards 2014 (Shortlisted)"
        bshadow="true"
      />
    </MainContainer>
  )
}
